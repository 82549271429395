import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBToggle } from 'bootstrap-vue'
import VueCalendly from 'vue-calendly'
import VueCompositionAPI from '@vue/composition-api'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import JsonViewer from 'vue-json-viewer'
import i18n from '@/libs/i18n'
import VueAnalytics from 'vue-analytics'
import store from './store'
import App from './App.vue'
import router from './router'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.directive('b-toggle', VBToggle)
Vue.use(JsonViewer)
Vue.use(VueCalendly)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

Vue.use(VueAnalytics, {
  id: 'UA-186932192-1',
  router,
})

Vue.mixin({
  methods: {
    getColor(item) {
      if (item === 'Info') return 'secondary'
      if (item === 'Low') return 'info'
      if (item === 'Medium') return 'warning'
      return 'danger'
    },
    capitalizeWords(string) {
      return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
    },
    getName() {
      if (window.location.hostname === 'allsaved.saasment.com') {
        return 'Allsaved Compliance'
      }
      if (window.location.hostname === 'rmcag.saasment.com') {
        return 'RMC Advisory Group'
      }
      return 'Saasment'
    },
    showJsonViewer(raw) {
      return typeof (raw) === 'object' ? raw : JSON.parse(raw)
    },
  },
})

Sentry.init({
  Vue,
  dsn: 'https://aa6ed86a144248b493b6adecade251fb@o581585.ingest.sentry.io/5736111',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
